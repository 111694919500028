import React from 'react';
import { UserContext } from '../../UserContext';
import styles from './PhotoComments.module.css';

const PhotoFiles = (props) => {
  const [files, setFiles] = React.useState(() => props.files);
  const commentsSection = React.useRef(null);
  const { login } = React.useContext(UserContext);

      
  function checaextensao (imagem) {

    var arq = imagem;
    var ext = arq.split('.').pop();

    if (ext === 'webp' || ext === 'gif' || ext === 'jpeg' || ext === 'jpg' || ext === 'png') {
      return (
        <img className="img-fluid rounded" onClick={() => props.abrirImg(process.env.PUBLIC_URL + arq)} src={process.env.PUBLIC_URL + arq} alt=""/>
      )} else if (ext === 'pdf' || ext === 'mp4') {
        return (
          <button className='btn btn-primary' onClick={() => props.abrirPdf(process.env.PUBLIC_URL + arq)}>{imagem}</button>  
        )} 
        else if (ext === 'js' || ext === 'html' || ext === 'htm' || ext === 'css') {
          return (
            <button className='btn btn-primary' onClick={() => props.abrirPdf(process.env.PUBLIC_URL + arq)}>{imagem}</button>  
        )}        
        else if (ext === 'mp42') {
          return (
            <video width="100%" autoplay controls>
            <source src={process.env.PUBLIC_URL + arq} type="video/mp4"/>
            </video>
        )}        
        else if (ext === 'ogg' || ext === 'mp3' || ext === 'wav' || ext === 'm4a') {
          return (
            <audio controls>
            <source src={process.env.PUBLIC_URL + arq} type="audio/mpeg"/>
            </audio>
        )} else {
          return (
            <a href={process.env.PUBLIC_URL + arq} target="_blank" rel="noreferrer">{imagem}</a>  
          )
        }

  }

  React.useEffect(() => {
    commentsSection.current.scrollTop = commentsSection.current.scrollHeight;
    //console.log ('comentariox');
    //console.log(props.comments);
    //props.comments = JSON.parse(props.comments); 
  }, [files]);

  return (
    <div style={{ backgroundColor : '#f2f2f2', overflowY: 'auto', height: 'calc(100vh - 200px)'}} >
      <ul
        ref={commentsSection}
        className={`${styles.comments} ${props.single ? styles.single : ''}`}
        style={{ display: 'flex', flexWrap: 'wrap' , listStyle: 'none', padding: '10px'}}
      >
        
        {files && files.map((file,index) => (
          <li key={index}>
            {checaextensao(file.imagem)}
          </li>
        ))}

      </ul>
    </div>
  );
};

export default PhotoFiles;

import React from 'react';
import styles from './PhotoContent.module.css';
import { Link } from 'react-router-dom';
import PhotoComments from './PhotoComments';
import PhotoFiles from './PhotoFiles';
import PhotoDelete from './PhotoDelete';
import PhotoUpdate from './PhotoUpdate';
import Image from '../Helper/Image';
import swal from 'sweetalert';
import { PHOTO_GET, LIKES_GET, FAVORITES_TOOGLE, FAVORITES_GET } from '../../Api';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPhoto } from '../../store/photo';
import moment from 'moment';

import Magnifier from "react-magnifier";
import { GlassMagnifier } from "../../magnifier";
import { initMindWired } from "@mind-wired/core";
import { loadFromServer } from "../api";
import "@mind-wired/core/dist/style.css";
import FeedModalInterno from '../Feed/FeedModalInterno';

const PhotoContent = ({ onClose, data, single }) => {
  //const user = React.useContext(UserContext);
  const user = useSelector((state) => state.user);
  const { photo, comments, files } = data;
  const [iframe, setIframe] = React.useState(false);
  const [qualquer, setQualquer] = React.useState(null);
  const [iframelink, setIframeLink] = React.useState('');
  const [imgP, setimgP] = React.useState();
  const [fav, setFav] = React.useState(false);
  const [favitem, setFavItem] = React.useState(false);
  const [layout, setLayout] = React.useState(false);
  const [lupa, setLupa] = React.useState(false);
  const [peq, setPeq] = React.useState(false);
  const [peqimg, setPeqImg] = React.useState(false);
  const [html, setHtml] = React.useState(photo.html);
  const [css, setCss] = React.useState(photo.css);
  const [js, setJs] = React.useState(photo.js);
  const dispatch = useDispatch();
  const nodeRef = React.useRef();
  const nodeMag = React.useRef();
  const [modalPhoto, setModalPhoto] = React.useState(null);
  const [modal, setModal] = React.useState(false);
  const ver = useSelector((state) => state.photo);

  const sendToBackend = (data) => {
    //setMapax(data.node);
  }

  React.useEffect(() => {
    if (photo.map && photo.area_id == 5) 
      { mapasmentais();
        setLayout(!layout);
      } 
      else if (photo.page) 
        { abrirIframe(photo.page) }
      else if (photo.src) { 
         { setimgP(photo.src) }
      }
    if (user.data) {
      setFav(true); 
      getFavorite(photo.id);
    }
    console.log(photo);

  }, []); 

  function abrirImg(link) {
    setIframe(false);
    setimgP(link);
    //nodeMag.current.img.src = link;
  }

  function abrirPdf(link) {
    setIframe(false);
    setimgP(link);
    //nodeMag.current.img.src = link;
  }

  function abrirMp4(link) {
    setIframe(false);
    setimgP(link);
    //nodeMag.current.img.src = link;
  }

  async function mapasmentais () {

    //const novo = document.createElement("div");
    //novo.id = "#mmap-root";
    //novo.style.cssText = 'width: 100%;position:relative;';
    //document.body.appendChild(novo);
  
    const mapData = await loadFromServer();
    var el = document.querySelector("#mmap-root");
    var mwd = await initMindWired({
      el,
      ui: {
                  width: '100%',
                  height: '100vh',
                  scale: 1.0,
                  clazz: {
                  node: "active-node",
                  edge: "active-edge",
                  schema: (schemaName) => schemaName,
                  level: (level) => `level-${level}`,
                  },
                  offset: { x: 0, y: 0 },
                  snap: {
                  limit: 4,
                  width: 0.4,
                  dash: [6, 2],
                  color: { horizontal: "orange", vertical: "#2bc490" },
                  },
                  selection: {
                  padding: 5,
                  "background-color": "#b3ddff6b",
                  "border-radius": "4px",
                  },
  
          }
  });
  
    
    mwd.nodes(JSON.parse(photo.map));
  
    mwd
    .listen("node.selected", async (NodeEventArg) => {
      const data = await mwd.exportWith();
      sendToBackend(data)
      })
    .listen("node.clicked", async (NodeEventArg) => {
      console.log(NodeEventArg.nodes[0].spec.model.text);
      const data = await mwd.exportWith();
      outraIdeia(NodeEventArg, NodeEventArg.nodes[0].spec.model.text);
      //sendToBackend(data);
      })
    .listen("node.updated", async (NodeEventArg) => {
      const data = await mwd.exportWith();
      sendToBackend(data)
    }).listen("node.created", async (NodeEventArg) => {
      const data = await mwd.exportWith();
      sendToBackend(data)
    });  
  
  
    //console.log(mwd);
  // console.log(window.mindwired);
  
    }
    

  function checaextensao (imagem) {

    var animacoes = ['https://www.mybrain.com.br/storage/pages/animacao-javascript.html','https://www.mybrain.com.br/storage/pages/animacao-waves.html',
    'https://www.mybrain.com.br/storage/pages/animacao-halo.html','https://www.mybrain.com.br/storage/pages/animacao-rings.html','https://www.mybrain.com.br/storage/pages/animacao-dots.html',
  'https://www.mybrain.com.br/storage/pages/animacao-topology.html', 'https://www.mybrain.com.br/storage/pages/animacao-trunk.html', 'https://www.mybrain.com.br/storage/pages/animacao-cells.html',
'https://www.mybrain.com.br/storage/pages/animacao-net.html', 'https://www.mybrain.com.br/storage/pages/animacao-globe.html'];
    const random = Math.floor(Math.random() * animacoes.length);
    var arq = imagem;
    var ext = arq.split('.').pop();

        if (ext === 'ogg' || ext === 'mp3' || ext === 'wav' || ext === 'm4a') {
            const player = document.getElementById('mp3back');
            player.src = imagem;  
            player.play();  
            setPeq(true);
            setPeqImg(true);
            setIframe(true);
            setIframeLink(animacoes[random]);                
        } else {
          setPeq(true);
          setPeqImg(true);
          setIframe(true);
          setIframeLink(imagem);
        }

  }

  function abrirQualquerCoisa(link) {
    setQualquer(checaextensao(link));
  }

  function abrirIframe(link) {
    checaextensao(link);
    //let photo = document.getElementById('photo');
    //photo.classList.add("peqphoto");
    //console.log (photo);
  }

  function updatePage() {
    setIframe(true);
    setIframeLink(photo.page);
    updatePageG();
  }

  function ChangeLayout() {
    setLayout(!layout);
  }

  function ChangeLupa() {

    let btnfav = document.getElementById('btnlupa');
    let inside = btnfav.getElementsByTagName('i')[0];

    if (lupa) {
      inside.classList.remove("fa-search-plus");
      inside.classList.add("fa-search");
      inside.style.color = 'unset';
    } else { 
      inside.classList.remove("fa-search");
      inside.classList.add("fa-search-plus");
      inside.style.color = 'yellow';
    }

    setLupa(!lupa);
  }

  function updatePageG() {
    var iframe = document.getElementById('page');
    if (iframe) iframe.src = iframe.src;
  }

  async function setLike(e,id) {
    try {
      const { url, options } = LIKES_GET(id);
      const likes = await fetch(url, options);
      const result = await likes.json();
      console.log(result.likes);
      console.log(e.persist());
      dispatch(fetchPhoto(id));
    } catch (err) {
      console.log(err);
    } finally {
    }
  }

  async function outraIdeia(geral, id) {
    try {
      const { url, options } = PHOTO_GET(id);
      const getFav = await fetch(url, options);
      const result = await getFav.json();
      const el = geral.nodes[0].$el;
      //el.innerHTML += result.photo.title;
      //el.style.width = "300px";

      let div = document.createElement('div');
      div.classList.add('mapaabsolute');
      let text = document.createTextNode(result.photo.title);
      div.appendChild(text);
      el.appendChild(div);

      
      setModalPhoto(result.photo);
      setModal(true);

    } catch (err) {
      console.log(err);
    } finally {
    }
  }

  function setCopiar (e,id) {
    e.preventDefault()

    var copyText = document.getElementById(id);

    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */
    document.execCommand("copy");

    swal("Copiado com sucesso!", {
        icon: "success",
        timer: 1000
        });

  }

  function copyText(e, item) {
    e.preventDefault();

    let element = nodeRef.current;
    element.innerHTML = item;

    console.log(element);
    
    var range, selection, worked;

    if (document.body.createTextRange) {
      range = document.body.createTextRange();
      range.moveToElementText(element);
      range.select();
    } else if (window.getSelection) {
      selection = window.getSelection();        
      range = document.createRange();
      range.selectNodeContents(element);
      selection.removeAllRanges();
      selection.addRange(range);
    }
    
    try {
      document.execCommand('copy');
      swal("Copiado com sucesso!", {
        icon: "success",
        timer: 1000
        });

    }
    catch (err) {
      swal("Não deu!", {
        icon: "error",
        timer: 1000
        });
    }
    
  }

  async function getFavorite(id) {
    try {
      const { url, options } = FAVORITES_GET(id);
      const getFav = await fetch(url, options);
      const result = await getFav.json();
      
      let btnfav = document.getElementById('btnfavorite');
      let inside = btnfav.getElementsByTagName('i')[0];

      if (result == 1) {
        inside.classList.remove("fa-star-half");
        inside.classList.add("fa-star");
        setFavItem(true);
      } else { 
        inside.classList.remove("fa-star");
        inside.classList.add("fa-star-half");
        setFavItem(false);
      }

    } catch (err) {
      console.log(err);
    } finally {
    }
  }

  async function setFavorite(e,id) {
    try {
      const { url, options } = FAVORITES_TOOGLE(id);
      const likes = await fetch(url, options);
      getFavorite(id);
      //let inside = e.currentTarget.getElementsByTagName('i')[0];
      //inside.classList.remove("fa-star-half");
      //inside.classList.add("fa-star");
    } catch (err) {
      console.log(err);
    } finally {
    }
  }

  function setLike2(e,id) {
    try {
      const { url, options } = LIKES_GET(id);
      const likes = fetch(url, options);
      e.currentTarget.innerHTML = 'Curtido';
      e.currentTarget.disabled = true; 
    } catch (err) {
      console.log(err);
    } finally {
    }
  }

  return (
<div className={`${layout ? 'container-fluid' : 'container'}`}>
<div className="row">
<div className="col-md-12 mb-3">

{modal && (
        <FeedModalInterno photo={modalPhoto} setModal={setModal} setModalPhoto={setModalPhoto} />
      )}

<nav className="navbar navbar-expand-lg navbar-dark bg-dark">
  <div className="container-fluid">
    <Link className="navbar-brand" to={`/idea/${photo.slug}`}>Ideia: {photo.id}</Link>
    <div className='d-flex gap-3'>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    {onClose && 
              <button id="close" className="btn btn-danger navbar-toggler" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" onClick={(e) => onClose(e)}>X</button>
    }
    </div>
    <div className="collapse navbar-collapse" id="navbarScroll">
      <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll" style={{bsScrollHeight: '100px'}}>
        <li className="nav-item">
          {photo.date && (
            <a className="nav-link" href="#">Data: { moment(`${photo.date}`).format('DD/MM/YYYY HH:mm') }</a>
          )}                    
        </li>
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="#" id="navbarScrollingDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Conheça também
          </a>
          <ul className="dropdown-menu" aria-labelledby="navbarScrollingDropdown">
            <li><a className="dropdown-item" href="https://mikagame.com.br/" target="_blank">Mikagame</a></li>
            <li><hr className="dropdown-divider"/></li>
            <li><a className="dropdown-item" href="https://brazilcats.com.br/" target="_blank">Brazilcats</a></li>
            <li><hr className="dropdown-divider"/></li>
            <li><a className="dropdown-item" href="https://sociedademonstros.com.br/" target="_blank">Sociedade Monstros</a></li>
          </ul>
        </li>
      </ul>
      <div className="d-flex">

              <div className="btn-group mx-1">
               <button id="btnlupa" type="button" onClick={(e) => ChangeLupa(e)} className="btn btn-secondary"><i className="fa fa-search"></i></button>
              </div>

              <div className="btn-group mx-1">
               <button type="button" onClick={(e) => ChangeLayout(e)} className="btn btn-secondary"><i className="fa fa-television"></i></button>
              </div>

              <div className="btn-group mx-1">
               <button type="button" onClick={(e) => updatePageG(e)} className="btn btn-secondary"><i className="fa fa-refresh"></i></button>
              </div>

              {fav &&
              <div className="btn-group mx-1">
               <button id="btnfavorite" type="button" onClick={(e) => setFavorite(e,photo.id)} className="btn btn-secondary"><i className="fa fa-star-half"></i></button>
              </div>
              }

              <div className="btn-group mx-1">
               <button type="button" onClick={(e) => setLike2(e,photo.id)} className="btn btn-secondary"><i className="fa fa-hand-pointer-o"></i> Curtir</button>
              </div>

              {onClose && 
              <button id="close" onClick={(e) => onClose(e)} className="btn btn-danger d-none d-md-block">X</button>
              }

      </div>
    </div>
  </div>
</nav>



  <div id="photo" className={`${peq == true ? styles.peq : ''} ${single == false ? styles.photoplana : styles.photo} ${layout ? styles.land : ''} ${single ? styles.single : ''}`}>



      <div  className={`${peqimg == true ? styles.peqimg : ''} ${styles.img}`}>

          {iframe ? (
            <iframe id="page" src={iframelink} className={`${single == false ? styles.iframeplana : styles.iframe}`} allow="camera;microphone" title="Iframe"></iframe>
          )
          : lupa ? 
		      <Magnifier ref={nodeMag} src={imgP} className={`${single == false ? styles.magplana : styles.mag}`} />
          : <img src={imgP} className='img-fluid'/>
          }

          {qualquer}

          <div id="mmap-root" style={{width: '100%', position: 'relative'}}></div>  

      </div>


      <div className={`${single == false ? styles.plano : styles.details}`}>


      <div style={{ margin: '10px'}}>
          <p className={styles.author}>
            {user.data && user.data.username == photo.author ? (
              <PhotoUpdate id={photo.id} html={html} css={css} js={js} atualizar={updatePage} />
            ) : (
              <Link to={`/perfil/${photo.author}`}>@{photo.author}</Link>
            )}
            <span className={styles.visualizacoes}>{photo.acessos}</span>
            <span className={styles.likes}>{photo.likes}</span>
          </p>
          {photo.title && (
            <div style={{textAlign : 'left'}}>
              <h1 className="title">
                <Link to={`/idea/${photo.slug}`}>{photo.title}</Link>
              </h1>
              <input type="text" value="" readOnly style={{display : 'none'}} id="h1geral">
              </input>
              <p style={{ opacity : '0', height: '0' }} ref={nodeRef} onClick={() => copyText(this)}></p>

              {photo.link && (
                <div className="btn-group m-1">
                  <button type="button" onClick={(event) => abrirIframe(photo.link)} className="btn btn-primary"><i className="fa fa-link" aria-hidden="true"></i> Abrir</button>
                  <button type="button" className="btn btn-primary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
                    <span className="visually-hidden">Toggle Dropdown</span>
                  </button>
                  <ul className="dropdown-menu">
                    <li><a id="txtlink" className="dropdown-item" href={photo.link} target="_blank">Abrir link em nova guia</a></li>
                    <li><a className="dropdown-item" onClick={(e) => copyText(e,photo.link)} href={photo.link}>Copiar Link</a></li>
                  </ul>
                </div>
              )}

              {photo.doc && (
                <div className="btn-group m-1">
                  <button type="button" onClick={(event) => abrirIframe(photo.doc)} className="btn btn-primary"><i className="fa fa-link" aria-hidden="true"></i> Documento</button>
                  <button type="button" className="btn btn-primary dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
                    <span className="visually-hidden">Toggle Dropdown</span>
                  </button>
                  <ul className="dropdown-menu">
                    <li><a id="txtdoc" className="dropdown-item" href={photo.doc} target="_blank">Abrir documento em nova guia</a></li>
                    <li><a className="dropdown-item" onClick={(e) => copyText(e,photo.doc)} href={photo.doc}>Copiar Link</a></li>
                  </ul>
                </div>
              )}
              {photo.video && (
                <div className="btn-group m-1">
                  <button type="button" onClick={(event) => abrirIframe(`https://www.youtube.com/embed/${photo.video}`)} className="btn btn-success"><i className="fa fa-link"></i> Vídeo</button>
                  <button type="button" className="btn btn-success dropdown-toggle dropdown-toggle-split" data-bs-toggle="dropdown" aria-expanded="false">
                    <span className="visually-hidden">Toggle Dropdown</span>
                  </button>
                  <ul className="dropdown-menu">
                    <li><a id="txtvideo" className="dropdown-item" href={`https://www.youtube.com/watch?v=${photo.video}`} target="_blank">Abrir video em nova guia</a></li>
                    <li><a className="dropdown-item" onClick={(e) => copyText(e,`https://www.youtube.com/watch?v=${photo.video}`)} href={photo.video}>Copiar Link</a></li>
                  </ul>
                </div>
              )}
            </div>
          )}

        </div>


      <div className="row" style={{padding: '0 10px'}}>
      <div className="col-12">
      <div className="card card-primary card-outline card-outline-tabs" style={{boxShadow: 'none'}}>
      <div className="card-header p-0 pt-1 border-bottom-0">
      <ul className="nav nav-tabs" id="custom-tabs-three-tab" role="tablist">
      <li className="nav-item">
        <a className="nav-link active" id="custom-tabs-three-home-tab" data-toggle="pill" href="#custom-tabs-three-home" role="tab" aria-controls="custom-tabs-three-home" aria-selected="false">Ideia</a>
      </li>
      <li className="nav-item">
        <a className="nav-link" id="custom-tabs-three-profile-tab" data-toggle="pill" href="#custom-tabs-three-profile" role="tab" aria-controls="custom-tabs-three-profile" aria-selected="false">Comentários</a>
      </li>

      {photo.html &&          
      <li className="nav-item">
        <a className="nav-link" id="custom-tabs-html-profile-tab" data-toggle="pill" href="#custom-tabs-html-profile" role="tab" aria-controls="custom-tabs-html-profile" aria-selected="false">Html</a>
      </li>}

      {photo.css &&          
      <li className="nav-item">
        <a className="nav-link" id="custom-tabs-css-profile-tab" data-toggle="pill" href="#custom-tabs-css-profile" role="tab" aria-controls="custom-tabs-css-profile" aria-selected="false">Css</a>
      </li>}

      {photo.js &&          
      <li className="nav-item">
        <a className="nav-link" id="custom-tabs-js-profile-tab" data-toggle="pill" href="#custom-tabs-js-profile" role="tab" aria-controls="custom-tabs-js-profile" aria-selected="false">Js</a>
      </li>}

      {files?.length != 0 &&
      <li className="nav-item">
        <a className="nav-link" id="custom-tabs-js-profile-tab" data-toggle="pill" href="#custom-tabs-files-profile" role="tab" aria-controls="custom-tabs-files-profile" aria-selected="false">Arquivos</a>
      </li>}


      </ul>
      </div>
      <div className="card-body" style={{ margin: 0, padding: 0}}>
      <div className="tab-content" id="custom-tabs-three-tabContent">
      <div className="tab-pane fade active show" id="custom-tabs-three-home" role="tabpanel" aria-labelledby="custom-tabs-three-home-tab">

      {photo.body && photo.area_id != 2 && (

      <div 
        className={`ck-content ${single == false ? styles.editorplana : styles.editor}`}
        id="editor"
        name="editor" 
        dangerouslySetInnerHTML={{ __html: photo.body }}>
      </div>
                         
      )} 

      {photo.body && photo.area_id == 2 && (

      <pre style={{textAlign: 'left', maxHeight: '50vh'}}>{photo.body}
      </pre>
                   
      )} 
         

      </div>
      <div className="tab-pane fade" id="custom-tabs-three-profile" role="tabpanel" aria-labelledby="custom-tabs-three-profile-tab">

      <PhotoComments single={single} id={photo.id} comments={comments} />

      </div>

      {photo.html &&
      <div className="tab-pane fade" id="custom-tabs-html-profile" role="tabpanel" aria-labelledby="custom-tabs-html-profile-tab">

        <textarea id="html" value={html} onChange={(event) => setHtml(event.target.value)} name="html" rows="4" cols="50" style={{width: '100%', outline : '0', border: '0', height : 'calc(100vh - 370px)'}}/>

      </div>}

      {photo.css &&
      <div className="tab-pane fade" id="custom-tabs-css-profile" role="tabpanel" aria-labelledby="custom-tabs-css-profile-tab">

        <textarea id="css" value={css} onChange={(event) => setCss(event.target.value)} name="css" rows="4" cols="50" style={{width: '100%', outline : '0', border: '0', height : 'calc(100vh - 370px)'}}/>

      </div>}

      {photo.js &&
      <div className="tab-pane fade" id="custom-tabs-js-profile" role="tabpanel" aria-labelledby="custom-tabs-js-profile-tab">


        <textarea id="js" value={js} onChange={(event) => setJs(event.target.value)} name="js" rows="4" cols="50" style={{width: '100%', outline : '0', border: '0', height : 'calc(100vh - 370px)'}}/>


      </div>}

      
      {files?.length != 0 &&
      <div className="tab-pane fade" id="custom-tabs-files-profile" role="tabpanel" aria-labelledby="custom-tabs-files-profile-tab">
      <PhotoFiles single={single} id={photo.id} abrirImg={abrirImg} abrirPdf={abrirIframe} files={files} />
      </div>}

      </div>
      </div>

      </div>
      </div>
      </div>
      </div>

    </div>

    </div>
    </div>
    </div>
  );
};

export default PhotoContent;
